import request from '../../request'
import qs from 'qs'

//  在线作业批阅 - 查询
const QueryEduCourseWorkSetReview = (p) => {
    return request.get(`eduCourseWorkSetTea/queryEduCourseWorkSetReview?${qs.stringify(p)}`).then(res => res.data);
}
// 在线作业-批阅-查看学生情况-查看列表（未批阅列表/已批阅列表)
const IsReviewHomeWorkByWorkId = (p) => {
    return request.get(`eduCourseWorkSetTea/isReviewHomeWorkByWorkId?${qs.stringify(p)}`).then(res => res.data);
}
// 在线作业-批阅-驳回
const WithdrawCourseStudentWorkAnswer = (data) => {
    return request.post(`stuCourseDetail/withdrawCourseStudentWorkAnswer`, data).then(res => res.data);
}
// 在线作业批阅-查看
const QueryStudentWorkById = (p) => {
    return request.get(`eduCourseWorkSetTea/queryStudentWorkById?${qs.stringify(p)}`).then(res => res.data);
}
// 在线作业-批阅
const TeacherReviewEduCourseWork = (data) => {
    return request.post(`eduCourseWorkSetTea/teacherReviewEduCourseWork`, data).then(res => res.data);
}

// 在线考试批阅 - 查询
const QueryEduCourseExamReview = (p) => {
    return request.get(`eduExamBatch/queryEduCourseExamReview?${qs.stringify(p)}`).then(res => res.data)
}
// 在线考试 - 批阅 - 查询
const TeaCourseStudentExamList = (data) => {
    return request.post(`TeaCourseStudentExam/list`, data).then(res => res.data);
}
// 在线考试批阅 - 批阅 - 查看
const QueryStudentExamById = (p) => {
    return request.get(`eduCourseExamSet/queryStudentExamById?${qs.stringify(p)}`).then(res => res.data)
}
// 在线考试批阅 - 批阅
const TeacherReviewEduCourseExam = (data) => {
    return request.post(`eduCourseWorkSetTea/teacherReviewEduCourseExam`, data).then(res => res.data);
}
export {
    QueryEduCourseWorkSetReview,
    IsReviewHomeWorkByWorkId,
    QueryStudentWorkById,
    WithdrawCourseStudentWorkAnswer,
    TeacherReviewEduCourseWork,

    QueryEduCourseExamReview,
    TeaCourseStudentExamList,
    QueryStudentExamById,
    TeacherReviewEduCourseExam
}