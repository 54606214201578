import { render, staticRenderFns } from "./DetailPreview.vue?vue&type=template&id=654bd1a3&scoped=true"
import script from "./DetailPreview.vue?vue&type=script&lang=js"
export * from "./DetailPreview.vue?vue&type=script&lang=js"
import style0 from "./DetailPreview.vue?vue&type=style&index=0&id=654bd1a3&prod&lang=less&scoped=true"
import style1 from "./DetailPreview.vue?vue&type=style&index=1&id=654bd1a3&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "654bd1a3",
  null
  
)

export default component.exports