var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Preview-T-WorkOnline-Module"},[_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"stu-module-header"},[_c('div',{staticClass:"stu-module-title"},[_vm._v("姓名："+_vm._s(_vm.datas.studentName))]),_c('div',{staticClass:"stu-module-title"},[_vm._v("总分："+_vm._s(_vm.datas.stuCore))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.back}},[_vm._v("返回")])],1),_c('div',{staticClass:"content"},[_c('el-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},_vm._l((_vm.data),function(item,index){return _c('el-collapse-item',{key:index,attrs:{"name":index}},[_c('template',{slot:"title"},[_c('span',{staticClass:"slot_total"},[_vm._v(" "+_vm._s(item.questionName))])]),_vm._l((item.eduCourseQuestionVos),function(c,i){return _c('div',{key:i,staticClass:"items"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"h_left"},[_c('div',[_c('div',{staticClass:"h_left_title"},[_c('span',{staticStyle:{"width":"28px","height":"28px"}},[(c.subjectiveFlag === 1)?_c('svg-icon',{attrs:{"name":c.correctFlag === 1
                            ? 'success'
                            : c.correctFlag === 0
                            ? 'error'
                            : ''}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(i + 1)+"、")]),_c('span',{domProps:{"innerHTML":_vm._s(c.questionTitle)}})])])]),(c.enabled === 1)?_c('span',{staticClass:"give_score",class:c.correctFlag === 1 || c.stuItemScore
                    ? 'success'
                    : c.correctFlag === 0 || !c.stuItemScore
                    ? 'error'
                    : ''},[_vm._v(" +"+_vm._s(c.stuItemScore)+"分 ")]):_vm._e()]),_vm._l((c.eduCourseQuestionOps),function(l,j){return _c('div',{key:j,staticClass:"list_ul"},[_c('ul',[_c('li',[_c('span',[_vm._v(_vm._s(l.itemKey)+"）")]),_c('span',[_vm._v(_vm._s(l.itemVal))])])])])}),_c('div',{staticClass:"referAnswer"},[_c('span',{staticClass:"green"},[_vm._v("用户所填答案：")]),_c('span',{domProps:{"innerHTML":_vm._s(c.answerVal)}},[_vm._v("{{}}")])]),_c('div',{staticClass:"referAnswer"},[_c('span',{staticClass:"green"},[_vm._v("参考答案：")]),_c('span',{domProps:{"innerHTML":_vm._s(c.questionKey)}},[_vm._v("{{}}")])]),_c('div',{staticClass:"referAnswer"},[_c('span',{staticClass:"green"},[_vm._v("问题解析：")]),_c('span',{staticStyle:{"font-weight":"bold","font-size":"15px","color":"#0099a6"},domProps:{"innerHTML":_vm._s(c.questionAnalyze)}})])],2)})],2)}),1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }